import React from 'react'
import Layout from '../../components/layout'
import { JumbleWordCreator } from '../../components/ChallengeCreators'

const JumbleWordPage = ({ challengeId }) => (
  <Layout>
    <JumbleWordCreator challengeId={challengeId} />
  </Layout>
)

export default JumbleWordPage
